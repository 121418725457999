@import '../../styles/variables.scss';

.restaurantHeaderImg {
  height: 40vh;
  img {
    overflow: hidden;
  }
}

.waveBackground {
  background-image: url('/restaurant_background.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  z-index: 2;
  min-width: 101%;
  @media (max-width: 800px) {
    height: 14vh;
    margin-top: -14vh;
  }
  @media (min-width: 800px) {
    height: 29vh;
    margin-top: -29vh;
  }
}

.backButton {
  width: 35px;
  height: 35px;
  background: $sizzlyPrimaryLight;
  margin-right: 10px;
  .arrowBack {
    color: $sizzlyPrimary;
    &:hover {
      color: $sizzlyPrimaryDark;
    }
  }
}

.articleElement,
.articleElement:focus:not(:focus-visible) {
  box-shadow: rgba(0, 0, 0, 0.13) 1px 0px 4px 1px;
  height: 12vh;
  min-height: 100px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 1px 0px 4px 1px;
  }
}

.articleHeading {
  position: absolute;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}

.articleDescription {
  top: 35%;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.articlePrice {
  position: absolute;
  right: 0;
  bottom: 0;
}

.articleImage {
  width: 20%;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
}

.headingWithoutImg {
  padding-top: 8vh;
}

@media (max-width: 40em) {
  .articleElement {
    width: 95%;
  }
  .articleHeading,
  .articleDescription {
    left: 20%;
    width: 75%;
  }
  .backButton {
    margin-left: 2%;
  }
}

@media (min-width: 40em) {
  .headingContainer,
  .articleContainer {
    width: 75%;
  }
  .articleElement {
    width: 100%;
  }
  .articleHeading,
  .articleDescription {
    left: 20%;
    width: 75%;
  }
}

.emergencyContainer {
  background: $systemRed;
  top: 65px;
  z-index: 200;
  justify-content: center;
  min-width: 100%;
}

.hintContainer {
  background: $sizzlyPrimary;
  top: 65px;
  z-index: 150;
  justify-content: center;
  min-width: 100%;
}

.emergencyShutdownHeading {
  margin-top: 75px !important;
}

.articleWrapper {
  min-height: 20vh;
}

.restaurantWrapper {
  z-index: 10;
  margin-top: 40vh;
}
