@import '../../styles/variables.scss';

.restaurantInfoCarouselContainer {
  padding: 0 40px;
  .restaurantInfoCarouselItem {
    width: 290px;
    min-height: 450px;
    height: 450px;
    display: flex;
    background: #fafafa;
    border-radius: 8px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    overflow: scroll;
    margin-bottom: 7px;
    overflow: auto;

    .restaurantInfoCarouselItemImage {
      width: 100%;
      max-height: 15vh;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px 8px 0 0;
    }
  }
  .customRightArrow {
    position: absolute !important;
    right: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(-45deg);
    &:hover {
      opacity: 1;
    }
  }
  .customLeftArrow {
    position: absolute !important;
    left: 3px;
    z-index: 1;
    border: 1px solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.5;
    cursor: pointer;
    transform: rotate(135deg);
    &:hover {
      opacity: 1;
    }
  }

  .restaurantInfoLogo {
    display: inline-block;
    margin-top: 8vh;
    width: 35%;
    border-radius: 8px;
    background: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    vertical-align: middle;
    padding: 10%;
    &::after {
      content: '';
      display: block;
    }
  }
}

.fontBold {
  font-weight: 500;
}
