@import '../../styles/variables.scss';

.modal-body {
  ::-webkit-scrollbar {
    background: black !important;
  }
}
.modalBody {
  background: #d0d0d0 !important;
  ::-webkit-scrollbar {
    background: black !important;
  }
}

.backgroundColor {
  background: #d0d0d0 !important;
}

.header {
  background: #d0d0d0;
}

.articleImage {
  height: 25vh;
}

.borderRadiusImg {
  border-radius: 8px;
  mix-blend-mode: multiply;
}

.sizzlyColor {
  color: $sizzlyPrimary;
  &:hover {
    color: $sizzlyPrimary;
  }
}

.sizzlyColorHover {
  @extend .sizzlyColor;
  &:hover {
    color: $sizzlyPrimaryDark;
  }
}

.truncatedDescription {
  max-height: 3em;
  overflow: hidden;
}

.optionsContainer {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.25) 0px 2px 8px 0px;
}

.fontWeightBold {
  font-weight: bold;
}

.orderButton {
  background: $sizzlyPrimary;
  border-radius: 25px;
  &:hover {
    background: $sizzlyPrimaryDark;
  }
}

.orderButtonDanger {
  @extend .orderButton;
  background: $systemRed;
}

.comment {
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.25) 0px 2px 8px 0px;
}

.buttonMargin {
  margin-bottom: 7vh;
}

.footerHeight {
  border: none;
}

.amountDiv {
  width: 40%;
}

.modalTitle {
  width: 90%;
}

.articleName {
  word-wrap: break-word;
}

.articleDescription {
  white-space: pre-wrap;
}
