@import '../../styles/variables.scss';

.categoryContainer {
  background: $sizzlyPrimary;
  overflow-wrap: break-word;
  margin: 5px;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  color: white;
  font-weight: bold;
  &::after {
    content: '';
    display: block;
    padding-bottom: 80%;
  }
  &:hover {
    color: white;
  }
  @media (max-width: 576px) {
    flex-basis: 45%;
    min-width: 160px;
  }
  @media (min-width: 576px) {
    flex-basis: 30%;
    min-width: 250px;
  }
}

.categoryContainerPhoto {
  @extend .categoryContainer;
  background: none;
  &:hover {
    color: white !important;
    -webkit-backdrop-filter: blur(30px) !important;
    backdrop-filter: contrast(40%) !important;
    background: linear-gradient(0.6turn, rgba($sizzlyPrimary, 0.9), 50%, rgba($systemRed, 0.9)) !important;
  }
}

.hyphenization {
  hyphens: auto;
}
