// Colors
$hoverBgColor: #503bff;
$hoverBgColor2: #8293ff;
$spinnerBgColor: rgba(0, 0, 0, 0.1);

$overviewTileBorderColor: #afafaf;
$overviewTileColor: #2417ba;

$sizzlyLight: #e9ecef;
$sizzlyGrey: #adb5bd;
$sizzlyUltraLight: #f8f9ff;

$sizzlyPrimaryUltraLight: #f8f9ff;
$sizzlyPrimaryLight: #cecef8;
$sizzlyPrimaryMedium: #9186fa;
$sizzlyPrimary: #462fee;
$sizzlyPrimaryDark: #2417ba;
$sizzlyPrimaryOpacity: #452fee0a;
$sizzlyPrimaryWineRed: #ad0957;
$sizzlyPrimaryWineRedMedium: #d683aa;
$sizzlyPrimaryWineRedDark: #800641;
$sizzlyPrimaryWineRedLight: #eeccdd;
$sizzlyPrimaryWineRedUltraLight: #fbf2f6;

$systemGreyUltraLight: #f2f2f7;
$systemGreyLight: #d9d9d9;
$systemGreyMedium: #c7c7cc;
$systemGreyDark: #636366;
$systemGreen: #28a745;
$systemYellow: #ffc107;
$systemRed: #dc3545;
$systemRedOpacity: #dc354615;

// Metrics
$radiusSmall: 8px;
$radiusBig: 50rem;
