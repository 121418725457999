@import '../../styles/variables.scss';

.description {
  max-height: 5.7em;
  overflow-y: hidden;
}

.expandButton {
  border: 2px solid $sizzlyPrimary;
  color: $sizzlyPrimary;
  &:hover {
    color: $sizzlyPrimary;
    border: 2px solid $sizzlyPrimary;
    background: $sizzlyPrimaryLight;
  }
}
