@import '../../styles/variables.scss';

.sizzlyColor {
  background: $sizzlyPrimary;
  height: 7vh;
  padding-left: 30%;
}

.orderTypeSelectionBackground {
  border-radius: 10px;
}
