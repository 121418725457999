@import '../../styles/variables.scss';

.restaurantHeaderImg {
  @media (max-width: 800px) {
    height: 40vh;
  }
  @media (min-width: 800px) {
    height: 55vh;
  }
  @media (min-width: 1200px) {
    height: 65vh;
  }
  overflow: hidden;
}

.restaurantHeader {
  background: white;
  z-index: 10;
  @media (max-width: 576px) {
    margin-top: -8vh;
  }
  @media (min-width: 992px) {
    width: 75%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
}

.waveBackground {
  background-image: url('/restaurant_background.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  min-width: 100%;
  @media (max-width: 800px) {
    height: 25vh;
    margin-top: 20vh;
  }
  @media (min-width: 800px) {
    min-height: 200px;
    height: 25vh;
    margin-top: 20vh;
  }
  @media (min-width: 1200px) {
    height: 35vh;
    margin-top: 30vh;
  }
}

.restaurantCardLogo {
  position: absolute;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: white;
  z-index: 100;
  left: 0;
  right: 0;
}

@media (max-width: 700px) {
  .restaurantCardLogo {
    width: 25%;
    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
  .restaurantToggle {
    width: 90%;
  }
}

@media (min-width: 700px) {
  .restaurantCardLogo {
    width: 170px;
    height: 160px;
  }
  .restaurantToggle {
    width: 60%;
  }
  .restaurantInfo {
    width: 80%;
  }
}

@media (min-width: 800px) {
  .restaurantToggle {
    width: 50%;
  }
}

@media (min-width: 1000px) {
  .restaurantCardLogo {
    margin-top: 50px;
  }
}

.restaurantToggle {
  font-weight: bold;
}

.restaurantToggle .leftButton {
  border-radius: $radiusBig !important;
}
.rightButton {
  border-radius: $radiusBig !important;
}
.leftButton {
  margin-right: -30px;
}
.leftButton,
.rightButton {
  color: $sizzlyPrimary;
  background: $sizzlyPrimaryLight;
  &:hover {
    background: $sizzlyPrimaryMedium;
    color: $sizzlyPrimaryDark;
  }
}

.rightButtonSelected {
  border-radius: $radiusBig !important;
  color: white;
  z-index: 10 !important;
  background: linear-gradient(
    to right,
    $sizzlyPrimaryLight 0%,
    $sizzlyPrimaryLight 50%,
    $sizzlyPrimary 50%,
    $sizzlyPrimary 100%
  );
  background-size: 203% 100%;
  background-position: -98% 0;
  transition: background-position 0.3s;
  &:hover {
    color: white;
    background-color: $sizzlyPrimaryDark;
  }
}

.leftButtonSelected {
  outline: none;
  border-radius: $radiusBig !important;
  color: white;
  margin-right: -30px;
  z-index: 10 !important;
  background: $sizzlyPrimary;
  background: linear-gradient(
    to right,
    $sizzlyPrimaryLight 0%,
    $sizzlyPrimaryLight 50%,
    $sizzlyPrimary 50%,
    $sizzlyPrimary 100%
  );
  background-size: 207% 100%;
  background-position: 97% 0;
  transition: background-position 0.3s;
  &:hover {
    color: white;
    background-color: $sizzlyPrimaryDark;
  }
}

.restaurantBackButton {
  width: 35px !important;
  height: 35px !important;
  background: $sizzlyPrimaryLight;
  .backArrow {
    color: $sizzlyPrimary;
    &:hover {
      color: $sizzlyPrimaryDark;
    }
  }
}

.divMargin {
  margin: 0 auto;
}

.restaurantInfo {
  @media (min-width: 992px) {
    width: 75%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
}

.emergencyContainer {
  background: $systemRed;
  top: 65px;
  z-index: 200;
  justify-content: center;
  min-width: 100%;
}

.hintContainer {
  background: $sizzlyPrimary;
  top: 65px;
  z-index: 150;
  justify-content: center;
  min-width: 100%;
}

.restaurantWrapper {
  z-index: 10;
}
